import { Routes, Route } from "react-router-dom";
import { Galvena } from "Pages/Galvena/Galvena";
import { Pakalpojumi } from "Pages/Pakalpojumi/Pakalpojumi";
import { Apmetums } from "Pages/Pakalpojumi/Components/Apmetums/Apmetums";
import { Elekroinstalacijas } from "Pages/Pakalpojumi/Components/Elekroinstalacijas/Elekroinstalacijas";
import { Vannas } from "Pages/Pakalpojumi/Components/Vannas/Vannas";
import { Santehnika } from "Pages/Pakalpojumi/Components/Santehnika/Santehnika";
import { Gridas } from "Pages/Pakalpojumi/Components/Gridas/Gridas";
import { Griesti } from "Pages/Pakalpojumi/Components/Griesti/Griesti";
import { Flizes } from "Pages/Pakalpojumi/Components/Flizes/Flizes";
import { Krasosana } from "Pages/Pakalpojumi/Components/Krasosana/Krasosana";
import { Kontakti } from "Pages/Kontakti/Kontakti";
import { ParMums } from "Pages/ParMums/ParMums";
import { MusuDarbi } from "Pages/MusuDarbi/MusuDarbi";

import {
  KONTAKTI,
  PAKALPOJUMI,
  PAR_MUMS,
  APMETUMS,
  ELECTRO,
  VANNAS,
  SANTEHNIKA,
  GRIDAS,
  GRIESTI,
  FLIZES,
  KRASOSANA,
  MUSU_DARBI,
} from "Consts/RoutePathes";

function Routing() {
  return (
    <Routes>
      <Route path="*" element={<Galvena />} />
      <Route path="/" element={<Galvena />} />
      <Route path={`${PAR_MUMS}`} element={<ParMums />} />
      <Route path={`${PAKALPOJUMI}`} element={<Pakalpojumi />} />
      <Route path={`${PAKALPOJUMI}/${APMETUMS}`} element={<Apmetums />} />
      <Route path={`${PAKALPOJUMI}/${ELECTRO}`} element={<Elekroinstalacijas />} />
      <Route path={`${PAKALPOJUMI}/${VANNAS}`} element={<Vannas />} />
      <Route path={`${PAKALPOJUMI}/${SANTEHNIKA}`} element={<Santehnika />} />
      <Route path={`${PAKALPOJUMI}/${GRIDAS}`} element={<Gridas />} />
      <Route path={`${PAKALPOJUMI}/${GRIESTI}`} element={<Griesti />} />
      <Route path={`${PAKALPOJUMI}/${FLIZES}`} element={<Flizes />} />
      <Route path={`${PAKALPOJUMI}/${KRASOSANA}`} element={<Krasosana />} />
      <Route path={`${KONTAKTI}`} element={<Kontakti />} />
      <Route path={`${MUSU_DARBI}`} element={<MusuDarbi />} />
    </Routes>
  );
}

export { Routing };
