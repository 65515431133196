import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "Media/DF_LOGO_NEW_transparent.png";
import { KONTAKTI, PAKALPOJUMI, PAR_MUMS, MUSU_DARBI } from "Consts/RoutePathes";
import { LngDropdown } from "./Components/LngDropdown";
import { useTranslation } from "react-i18next";

function Navigation() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={Logo} alt="Logo" height={"45px"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to={`/${PAR_MUMS}`} active={pathname === `/${PAR_MUMS}`}>
              {t("Par_mums_nav")}
            </Nav.Link>
            <Nav.Link as={Link} to={`/${PAKALPOJUMI}`} active={pathname.includes(`/${PAKALPOJUMI}`)}>
              {t("Pakalpojumi_nav")}
            </Nav.Link>
            <Nav.Link as={Link} to={`/${MUSU_DARBI}`} active={pathname === `/${MUSU_DARBI}`}>
              {t("Musud_nav")}
            </Nav.Link>
            <Nav.Link as={Link} to={`/${KONTAKTI}`} active={pathname === `/${KONTAKTI}`}>
              {t("Kontakti_nav")}
            </Nav.Link>
          </Nav>
          <Nav>
            <LngDropdown />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export { Navigation };
