import { PAKALPOJUMI } from "Consts/RoutePathes";
import Video from "Media/painter-compressed.mp4";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Galvena.css";
function Galvena() {

  const { t } = useTranslation();


  return (
    <>
      <section className="showcase1">
        <video src={Video} muted loop autoPlay playsInline></video>
        <div className="overlay1"></div>
        <div className="text1">
          <p>Deco-Factur</p>
          <Link to={`/${PAKALPOJUMI}`}>
            <button className="button-49">{t('Pakalpojumi_btn')}</button>
          </Link>
        </div>
      </section>
    </>
  );
}

export { Galvena };
