import { Phone, Email, Shield } from "@mui/icons-material";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import Logo from "Media/team2-compressed.jpg";
import { useTranslation } from "react-i18next";

function Kontakti() {
  const { t } = useTranslation();

  return (
    <>
      <iframe
        title="Google maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d70199.98317508498!2d23.641733129089616!3d56.64417328956551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ef254b6eed168f%3A0x400cfcd68f31c60!2z0JXQu9Cz0LDQstCw!5e0!3m2!1sru!2slv!4v1650707456740!5m2!1sru!2slv"
        style={{ border: 0, width: "100%", height: "300px" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="pb-3"
      ></iframe>

      <Container>
        <Row className="pb-5">
          <Col md={6} lg={8}>
            <img className="d-block w-100 pb-3" style={{ maxHeight: "400px", objectFit: "scale-down" }} src={Logo} alt="Company logo"></img>
          </Col>
          <Col md={6} lg={4}>
            <h4 style={{ textAlign: "center" }}>
              <strong>{t("Musu_kontakt_info")}</strong>
            </h4>
            <Container fluid>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Phone className="me-2" />

                  <a href="tel:+37120059207">+371 20059207</a>
                  <br />
                  <Phone className="me-2" />
                  <a href="tel:+37129591239">+371 29591239</a>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Email className="me-2" />
                  <strong>{t("E_mail_info")} </strong>
                  <a href="mailto:decofactur@gmail.com">decofactur@gmail.com</a>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Shield className="me-2" />
                  <strong>{t("Uznem_regnr_info")} </strong>
                  43603078884
                </ListGroup.Item>
              </ListGroup>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Kontakti };
