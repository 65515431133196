import { Carousel, Container, Row, Col } from "react-bootstrap";
import sh1 from "Media/el1.jpg";
import sh2 from "Media/el2.jpg";
import sh3 from "Media/el3.jpg";

import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
function Elekroinstalacijas() {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4 style={{ textAlign: "center" }}>{t("Elektroinstalacijas")}</h4>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <ol>
              <li>
                {t("Electro_li1")}
                <ul>
                  <li>{t("Electro_li1_1")}</li>
                </ul>
              </li>
              <li>
                {t("Electro_li2")}
                <ul>
                  <li>{t("Electro_li2_1")}</li>
                  <li>{t("Electro_li2_2")}</li>
                </ul>
              </li>
              <li>
                {t("Electro_li3")}
                <ul>
                  <li>{t("Electro_li3_1")}</li>
                  <li>{t("Electro_li3_2")}</li>
                  <li>{t("Electro_li3_3")}</li>
                </ul>
              </li>
              <li>
                {t("Electro_li4")}
                <ul>
                  <li>{t("Electro_li4_1")}</li>
                  <li>{t("Electro_li4_2")}</li>
                </ul>
              </li>
            </ol>
            <p className="text-center">
              {t("Electro_text1")}
            </p>
          </Col>
          <Col lg={6} className="my-3">
            <YouTube
              videoId="jHBIRNGslqA"
              containerStyle={{ minHeight: "300px" }}
              className="w-100 h-100"
              containerClassName="w-100 h-100"
            />
          </Col>
        </Row>
        <Row className="pb-5 px-3 justify-content-center">
          <Col lg={8} className="my-3">
            <Carousel style={{ maxHeight: "90vh", objectFit: "cover" }}>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh1} alt="First slide" />
                <Carousel.Caption>
                  <h3>{t("Electro_slide1")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh2} alt="Second slide" />

                <Carousel.Caption>
                  <h3>{t("Electro_slide2")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh3} alt="Third slide" />

                <Carousel.Caption>
                  <h3>{t("Electro_slide3")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Elekroinstalacijas };
