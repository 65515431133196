import { Button, Col, Image, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { getImagesArray } from "./Components/images";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function MusuDarbi() {
  const { t } = useTranslation();
  const images = getImagesArray();

  const imagesPerPage = 12;
  const pageCount = Math.trunc(images.length / imagesPerPage) + 1;

  const [currentImagePage, setCurrentImagePage] = useState(1);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [currentBigImage, setCurrentBigImage] = useState(undefined);

  function changePage(page) {
    if (page < 1 || page > pageCount) return;
    setCurrentImagePage(page);
  }

  return (
    <>
      <Container className="pb-5">
        <Row className="mt-2">
          <Col>
            <h1 className="text-center">{t("Musu_darb_Galery")}</h1>
          </Col>
        </Row>
        <Row className="mb-3 mt-2">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Button disabled={currentImagePage === 1} onClick={() => changePage(currentImagePage - 1)}>
              <ArrowBackIosNewIcon />
              {t("IepriekLap")}
            </Button>
            <div className="fs-3 px-3">
              {currentImagePage}/{pageCount}
            </div>
            <Button disabled={currentImagePage === pageCount} onClick={() => changePage(currentImagePage + 1)}>
              {t("NakamaLap")}
              <ArrowForwardIosIcon />
            </Button>
          </div>
        </Row>
        <Row className="justify-content-center">{renderGallery()}</Row>
        <Row className="mb-2 mt-3">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Button disabled={currentImagePage === 1} onClick={() => changePage(currentImagePage - 1)}>
              <ArrowBackIosNewIcon />
              {t("IepriekLap")}
            </Button>
            <div className="fs-3 px-3">
              {currentImagePage}/{pageCount}
            </div>
            <Button disabled={currentImagePage === pageCount} onClick={() => changePage(currentImagePage + 1)}>
              {t("NakamaLap")}
              <ArrowForwardIosIcon />
            </Button>
          </div>
        </Row>
      </Container>

      <Dialog
        maxWidth="xl"
        open={isImageOpen}
        onClose={() => {
          setIsImageOpen(false);
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsImageOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[300],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Image src={currentBigImage} style={{ maxWidth: "100%", maxHeight: "calc(100vh - 128px)", objectFit: "scale-down" }} />
      </Dialog>
    </>
  );

  function renderGallery() {
    let rows = [];
    let startIndex = imagesPerPage * (currentImagePage - 1);
    let lastIndex = images.length < imagesPerPage * currentImagePage ? images.length : imagesPerPage * currentImagePage;
    for (let index = startIndex; index < lastIndex; index++) {
      rows.push(
        <Col style={{ maxHeight: "30vh", minHeight: "300px" }} className="p-1" key={index} lg={3} md={4} sm={6}>
          <Button
            variant="link"
            className="w-100 h-100"
            onClick={() => {
              showBigImage(images[index].image);
            }}
          >
            <Image className="h-100 w-100" rounded thumbnail style={{ objectFit: "cover" }} src={images[index].thumbnail} />
          </Button>
        </Col>
      );
    }
    return rows;
  }

  function showBigImage(image) {
    if (isImageOpen) return;
    setIsImageOpen(true);
    setCurrentBigImage(image);
  }
}

export { MusuDarbi };
