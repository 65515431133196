import img1 from "Media/PhotoMus/1.jpg";
import img2 from "Media/PhotoMus/2.jpg";
import img3 from "Media/PhotoMus/3.jpg";
import img4 from "Media/PhotoMus/4.jpg";
import img5 from "Media/PhotoMus/5.jpg";
import img6 from "Media/PhotoMus/6.jpg";
import img7 from "Media/PhotoMus/7.jpg";
import img8 from "Media/PhotoMus/8.jpg";
import img10 from "Media/PhotoMus/10.jpg";
import img11 from "Media/PhotoMus/11.jpg";
import img12 from "Media/PhotoMus/12.jpg";
import img13 from "Media/PhotoMus/13.jpg";
import img14 from "Media/PhotoMus/14.jpg";
import img15 from "Media/PhotoMus/15.jpg";
import img17 from "Media/PhotoMus/17.jpg";
import img18 from "Media/PhotoMus/18.jpg";
import img19 from "Media/PhotoMus/19.jpg";
import img20 from "Media/PhotoMus/20.jpg";
import img21 from "Media/PhotoMus/21.jpg";
import img22 from "Media/PhotoMus/22.jpg";
import img23 from "Media/PhotoMus/23.jpg";
import img24 from "Media/PhotoMus/24.jpg";
import img25 from "Media/PhotoMus/25.jpg";
import img27 from "Media/PhotoMus/27.jpg";
import img28 from "Media/PhotoMus/28.jpg";
import img29 from "Media/PhotoMus/29.jpg";
import img30 from "Media/PhotoMus/30.jpg";

import img31 from "Media/PhotoMus/31.jpg";
import img32 from "Media/PhotoMus/32.jpg";
import img33 from "Media/PhotoMus/33.jpg";
import img34 from "Media/PhotoMus/34.jpg";
import img35 from "Media/PhotoMus/35.jpg";
import img36 from "Media/PhotoMus/36.jpg";
import img37 from "Media/PhotoMus/37.jpg";
import img38 from "Media/PhotoMus/38.jpg";

import img1thumb from "Media/PhotoMus/Compressed/1.jpg";
import img2thumb from "Media/PhotoMus/Compressed/2.jpg";
import img3thumb from "Media/PhotoMus/Compressed/3.jpg";
import img4thumb from "Media/PhotoMus/Compressed/4.jpg";
import img5thumb from "Media/PhotoMus/Compressed/5.jpg";
import img6thumb from "Media/PhotoMus/Compressed/6.jpg";
import img7thumb from "Media/PhotoMus/Compressed/7.jpg";
import img8thumb from "Media/PhotoMus/Compressed/8.jpg";
import img10thumb from "Media/PhotoMus/Compressed/10.jpg";
import img11thumb from "Media/PhotoMus/Compressed/11.jpg";
import img12thumb from "Media/PhotoMus/Compressed/12.jpg";
import img13thumb from "Media/PhotoMus/Compressed/13.jpg";
import img14thumb from "Media/PhotoMus/Compressed/14.jpg";
import img15thumb from "Media/PhotoMus/Compressed/15.jpg";
import img17thumb from "Media/PhotoMus/Compressed/17.jpg";
import img18thumb from "Media/PhotoMus/Compressed/18.jpg";
import img19thumb from "Media/PhotoMus/Compressed/19.jpg";
import img20thumb from "Media/PhotoMus/Compressed/20.jpg";
import img21thumb from "Media/PhotoMus/Compressed/21.jpg";
import img22thumb from "Media/PhotoMus/Compressed/22.jpg";
import img23thumb from "Media/PhotoMus/Compressed/23.jpg";
import img24thumb from "Media/PhotoMus/Compressed/24.jpg";
import img25thumb from "Media/PhotoMus/Compressed/25.jpg";
import img27thumb from "Media/PhotoMus/Compressed/27.jpg";
import img28thumb from "Media/PhotoMus/Compressed/28.jpg";
import img29thumb from "Media/PhotoMus/Compressed/29.jpg";
import img30thumb from "Media/PhotoMus/Compressed/30.jpg";

import img31thumb from "Media/PhotoMus/Compressed/31.jpg";
import img32thumb from "Media/PhotoMus/Compressed/32.jpg";
import img33thumb from "Media/PhotoMus/Compressed/33.jpg";
import img34thumb from "Media/PhotoMus/Compressed/34.jpg";
import img35thumb from "Media/PhotoMus/Compressed/35.jpg";
import img36thumb from "Media/PhotoMus/Compressed/36.jpg";
import img37thumb from "Media/PhotoMus/Compressed/37.jpg";
import img38thumb from "Media/PhotoMus/Compressed/38.jpg";

function getImagesArray() {
  const images = [
    {
      image: img1,
      thumbnail: img1thumb,
    },
    {
      image: img2,
      thumbnail: img2thumb,
    },
    {
      image: img3,
      thumbnail: img3thumb,
    },
    {
      image: img4,
      thumbnail: img4thumb,
    },
    {
      image: img5,
      thumbnail: img5thumb,
    },
    {
      image: img6,
      thumbnail: img6thumb,
    },
    {
      image: img7,
      thumbnail: img7thumb,
    },
    {
      image: img8,
      thumbnail: img8thumb,
    },

    {
      image: img10,
      thumbnail: img10thumb,
    },
    {
      image: img11,
      thumbnail: img11thumb,
    },
    {
      image: img12,
      thumbnail: img12thumb,
    },
    {
      image: img13,
      thumbnail: img13thumb,
    },
    {
      image: img14,
      thumbnail: img14thumb,
    },
    {
      image: img15,
      thumbnail: img15thumb,
    },

    {
      image: img17,
      thumbnail: img17thumb,
    },
    {
      image: img18,
      thumbnail: img18thumb,
    },
    {
      image: img19,
      thumbnail: img19thumb,
    },
    {
      image: img20,
      thumbnail: img20thumb,
    },
    {
      image: img21,
      thumbnail: img21thumb,
    },
    {
      image: img22,
      thumbnail: img22thumb,
    },
    {
      image: img23,
      thumbnail: img23thumb,
    },
    {
      image: img24,
      thumbnail: img24thumb,
    },
    {
      image: img25,
      thumbnail: img25thumb,
    },

    {
      image: img27,
      thumbnail: img27thumb,
    },
    {
      image: img28,
      thumbnail: img28thumb,
    },
    {
      image: img29,
      thumbnail: img29thumb,
    },
    {
      image: img30,
      thumbnail: img30thumb,
    },
    {
      image: img31,
      thumbnail: img31thumb,
    },
    {
      image: img32,
      thumbnail: img32thumb,
    },
    {
      image: img33,
      thumbnail: img33thumb,
    },
    {
      image: img34,
      thumbnail: img34thumb,
    },
    {
      image: img35,
      thumbnail: img35thumb,
    },

    {
      image: img36,
      thumbnail: img36thumb,
    },
    {
      image: img37,
      thumbnail: img37thumb,
    },
    {
      image: img38,
      thumbnail: img38thumb,
    },
  ];
  return images;
}

export { getImagesArray };
