import { Language } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function LngDropdown() {

    const { t, i18n } = useTranslation()
    const [languagePresent, setLanguagePresent] = useState(true)
    useEffect(() => {
        document.documentElement.lang = i18n.language.split("-")[0]
        if (i18n.language.includes("lv") || i18n.language.includes("ru") || i18n.language.includes("en")) {
            setLanguagePresent(true)
        } else {
            setLanguagePresent(false)
        }
    }, [i18n.language])

    return (
        <>
            <NavDropdown className="ml-auto" title={
                <span>
                    {<Language className="me-1" fontSize="inherit" />}
                    {t('Valodas_drop_nav')}
                </span>
            } id="collasible-nav-dropdown">
                <NavDropdown.Item
                    onClick={() => {
                        i18n.changeLanguage('lv-LV')
                    }}
                    active={i18n.language.includes("lv") || !languagePresent}
                >
                    Latviešu
                </NavDropdown.Item>
                <NavDropdown.Item
                    onClick={() => {
                        i18n.changeLanguage('ru-RU')
                    }}
                    active={i18n.language.includes("ru")}
                >
                    Русский
                </NavDropdown.Item>
                <NavDropdown.Item
                    onClick={() => {
                        i18n.changeLanguage('en-US')
                    }}
                    active={i18n.language.includes("en")}
                >
                    English
                </NavDropdown.Item>
            </NavDropdown>
        </>
    )
}

export { LngDropdown }