import { Carousel, Container, Row, Col } from "react-bootstrap";
import sh1 from "Media/1sh.jpg";
import sh2 from "Media/2sh.jpg";
import sh3 from "Media/3sh.jpg";

import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
function Apmetums() {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4 style={{ textAlign: "center" }}>{t("Dekorativais_apmetums")}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <p style={{ textAlign: "center" }}>{t("Apmetums_text1")}</p>
            <ul>
              <li>{t("Apmetums_li1")}</li>
              <li>{t("Apmetums_li2")}</li>
              <li>{t("Apmetums_li3")}</li>
              <li>{t("Apmetums_li4")}</li>
              <li>{t("Apmetums_li5")}</li>
              <li>{t("Apmetums_li6")}</li>
              <li>{t("Apmetums_li7")}</li>
              <li>{t("Apmetums_li8")}</li>
              <li>{t("Apmetums_li9")}</li>
              <li>{t("Apmetums_li10")}</li>
              <li>{t("Apmetums_li11")}</li>
            </ul>
            <p style={{ textAlign: "center" }}>{t("Apmetums_text2")}</p>
            <p style={{ textAlign: "center" }}> <strong> {t("Apmetums_text3")} </strong> </p>
            <ul>
              <li>{t("Apmetums_li12")}</li>
              <li>{t("Apmetums_li13")}</li>
              <li>{t("Apmetums_li14")}</li>
              <li>{t("Apmetums_li15")}</li>
              <li>{t("Apmetums_li16")}</li>
              <li>{t("Apmetums_li17")}</li>
            </ul>
          </Col>
          <Col md={6} className="my-3">
            <YouTube
              videoId="GdTyozdx8uY"
              containerStyle={{ minHeight: "300px" }}
              className="w-100 h-100"
              containerClassName="w-100 h-100"
            />
          </Col>
        </Row>
        <Row className="pb-5 px-3 justify-content-center">
          <Col lg={8} className="my-3">
            <Carousel style={{ maxHeight: "90vh", objectFit: "cover" }}>
              <Carousel.Item>
                <img style={{ maxHeight: "90vh", objectFit: "cover" }} className="d-block w-100" src={sh1} alt="First slide" />
                <Carousel.Caption>
                  <h3>{t("Apmetums_slide1")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh2} alt="Second slide" />

                <Carousel.Caption>
                  <h3>{t("Apmetums_slide2")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh3} alt="Third slide" />

                <Carousel.Caption>
                  <h3>{t("Apmetums_slide3")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>

        </Row>
      </Container>
    </>
  );
}

export { Apmetums };
