import { Carousel, Container, Row, Col } from "react-bootstrap";
import sh1 from "Media/pot1.jpg";
import sh2 from "Media/pot2.jpg";
import sh3 from "Media/pot3.jpg";

import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
function Griesti() {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4 style={{ textAlign: "center" }}>{t("Griesti")}</h4>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <ul>
              <li>{t("Griesti_li1")}</li>
              <li>{t("Griesti_li2")}</li>
              <li>{t("Griesti_li3")}</li>
              <li>{t("Griesti_li4")}</li>
              <li>{t("Griesti_li5")}</li>
            </ul>
            <p style={{ textAlign: "justify" }}>{t("Griesti_text1")}</p>
          </Col>
          <Col xl={6} className="my-3">
            <YouTube
              videoId="6OeS29M_v5c"
              containerStyle={{ minHeight: "300px" }}
              className="w-100 h-100"
              containerClassName="w-100 h-100"
            />
          </Col>
        </Row>
        <Row className="pb-5 px-3 justify-content-center">
          <Col lg={8} className="my-3">
            <Carousel style={{ maxHeight: "90vh", objectFit: "cover" }}>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh1} alt="First slide" />
                <Carousel.Caption>
                  <h3>{t("Griesti_slide1")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh2} alt="Second slide" />

                <Carousel.Caption>
                  <h3>{t("Griesti_slide2")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ maxHeight: "90vh", objectFit: "cover" }}>
                <img className="d-block w-100" style={{ maxHeight: "90vh", objectFit: "cover" }} src={sh3} alt="Third slide" />

                <Carousel.Caption>
                  <h3>{t("Griesti_slide3")}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Griesti };
