import { Carousel, Container, Row, Col } from "react-bootstrap";
import sh1 from "Media/doposle1.jpg";
import sh2 from "Media/doposle3.jpg";
import sh4 from "Media/doposle5.jpg";
import sh5 from "Media/doposle6.jpg";
import sh6 from "Media/doposle7.jpg";
import sh7 from "Media/doposle8.jpg";
import sh8 from "Media/doposle9.jpg";
import sh9 from "Media/doposle10.jpg";
import { useTranslation } from "react-i18next";
import Video from "Media/parmums-compressed.mp4";

function ParMums() {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Container>
          <Row>
            <Col xl={12}>
              <h2 className="text-center">
                <strong>{t("Par_mums")}</strong>
              </h2>
            </Col>
            <Col xl={7}>

              <p>{t("Par_mums_text")}</p>
            </Col>
            <Col xl={5}>
              <video className="w-100" src={Video} muted loop autoPlay controls playsInline></video>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col>
              <Carousel className="">
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh1} alt="First slide" />
                  <Carousel.Caption>
                    <h3>{t("Par_mums1")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh2} alt="Second slide" />

                  <Carousel.Caption>
                    <h3>{t("Par_mums2")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                  <img className="d-block w-100 " src={sh4} alt="First slide" />
                  <Carousel.Caption>
                    <h3>{t("Par_mums3")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh5} alt="Second slide" />

                  <Carousel.Caption>
                    <h3>{t("Par_mums4")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh6} alt="Third slide" />

                  <Carousel.Caption>
                    <h3>{t("Par_mums5")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh7} alt="First slide" />
                  <Carousel.Caption>
                    <h3>{t("Par_mums6")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh8} alt="Second slide" />

                  <Carousel.Caption>
                    <h3>{t("Par_mums7")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100 " src={sh9} alt="Third slide" />

                  <Carousel.Caption>
                    <h3>{t("Par_mums8")}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export { ParMums };
