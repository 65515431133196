export const PAR_MUMS = "about-us";
export const PAKALPOJUMI = "services";
export const APMETUMS = "apmetums";
export const ELECTRO = "elekroinstalacijas";
export const VANNAS = "vannas";
export const SANTEHNIKA = "santehnika";
export const GRIDAS = "gridas";
export const GRIESTI = "griesti";
export const FLIZES = "flizes";
export const KRASOSANA = "krasosana";
export const KONTAKTI = "contacts";
export const MUSU_DARBI = "musu-darbi";
