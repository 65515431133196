import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { APMETUMS, ELECTRO, VANNAS, SANTEHNIKA, GRIDAS, GRIESTI, FLIZES, KRASOSANA } from "Consts/RoutePathes";
import sh3 from "Media/3sh_logo.jpg";
import el1 from "Media/el1_logo.jpg";
import vn1 from "Media/vn1_logo.jpg";
import san1 from "Media/san1_logo.jpg";
import pl1 from "Media/pl1_logo.jpg";
import pot1 from "Media/pot2_logo.jpg";
import pok1 from "Media/pokraska_logo.jpg";
import plit1 from "Media/plitka_logo.jpg";
import { useTranslation } from "react-i18next";

function Pakalpojumi() {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h2 className="text-center">
          <strong>{t("Musu_pakalpojumi")}</strong>
        </h2>
        <Container className="pb-5">
          <Row>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={sh3} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Dekorativais_apmetums")}</Card.Title>

                  <Button as={Link} to={`${APMETUMS}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={el1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Elektroinstalacijas")}</Card.Title>

                  <Button as={Link} to={`${ELECTRO}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={vn1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Vannas_istabas")}</Card.Title>
                  <Button as={Link} to={`${VANNAS}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={san1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Santehnika")}</Card.Title>
                  <Button as={Link} to={`${SANTEHNIKA}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={pl1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Gridas")}</Card.Title>
                  <Button as={Link} to={`${GRIDAS}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={pot1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Griesti")}</Card.Title>
                  <Button as={Link} to={`${GRIESTI}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={plit1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Flizes")}</Card.Title>
                  <Button as={Link} to={`${FLIZES}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} lg={3} className="mt-2">
              <Card>
                <Card.Img variant="top" src={pok1} alt="Logo" />
                <Card.Body>
                  <Card.Title className="text-center">{t("Krasosana")}</Card.Title>
                  <Button as={Link} to={`${KRASOSANA}`} className="d-block" variant="primary">
                    {t("Uzzinat_vairak_btn")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export { Pakalpojumi };
