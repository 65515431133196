import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import lv from "./Components/lv";
import ru from "./Components/ru";
import en from "./Components/en";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  "lv-LV": {
    translation: lv,
  },
  "en-US": {
    translation: en,
  },
  "ru-RU": {
    translation: ru,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "lv-LV",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
