import "./Footer.css";

function Footer() {
  return (
    <div className="footer1">
      <h5>© SIA AJBA</h5>
    </div>
  );
}

export { Footer };
