import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Navigation } from "./Components/Navigation/Navigation.js";
import { Footer } from "./Components/Footer/Footer";
import { Routing } from "./Components/Routing/Routing";
import { Suspense } from "react";

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <Suspense fallback="loading">
        <Routing />
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
